import React from 'react';
import { Link } from 'gatsby';

import { Taxonomy } from '../model';
import { TaxonomyList } from './TaxonomyList';

interface CategoriesProps {
  categories: ReadonlyArray<Taxonomy>;
}

export const Categories = ({ categories }: CategoriesProps) => {
  return (
    <div className="prose mx-auto">
      <TaxonomyList title="Genres" taxonomies={categories} align="center" />
    </div>
  );
};
