import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { Categories } from '../components/Categories';

import { BlogPostOverview, Taxonomy } from '../model';

import { BlogPostsList } from '../components/BlogPostsList';
import { PageLayout } from '../components/PageLayout';

interface DataProps {
  allBlogPostsJson: {
    nodes: ReadonlyArray<BlogPostOverview>;
  };
  allTagsJson: {
    nodes: ReadonlyArray<Taxonomy>;
  };
  allCategoriesJson: {
    nodes: ReadonlyArray<Taxonomy>;
  };
}

const IndexPage = ({ data }: PageProps<DataProps>) => {
  return (
    <PageLayout title="" description="">
      <div>
        <p className="text-center font-medium text-lg subpixel-antialiased">
          Antworten auf drängende Fragen <br />
          des Lebens
        </p>
        <aside>
          <Categories categories={data.allCategoriesJson.nodes} />
        </aside>
        <h1 className="text-xl font-semibold mt-12">Alle Antworten</h1>
        <div>
          <BlogPostsList blogPosts={data.allBlogPostsJson.nodes} />
        </div>
      </div>
    </PageLayout>
  );
};

export const query = graphql`
  query {
    allBlogPostsJson(sort: { fields: meta___created, order: DESC }) {
      nodes {
        ...BlogPostOverviewFragment
      }
    }
    allTagsJson {
      nodes {
        ...TagsJsonFragment
      }
    }
    allCategoriesJson {
      nodes {
        ...CategoriesJsonFragment
      }
    }
  }
`;

export default IndexPage;
